import React, { useState, useEffect } from "react";
import io from "socket.io-client";
import "./App.css"

// here will be the backend url
// const socket = io("http://localhost:4000");
const socket = io("https://reactsocketbackend.onrender.com");

function App() {
  const [message, setMessage] = useState("");
  const [receivedMessage, setReceivedMessage] = useState([]);

  useEffect(() => {
    socket.on("receive_message", (data) => {
      // setReceivedMessage(data.message);
      setReceivedMessage(prevMsg => ([...prevMsg, data.message]))
    });



    return () => {
      socket.off("receive_message");
    };
  }, []);

  useEffect(() => {
    console.log('receivedMessage', receivedMessage)
  }, [receivedMessage])


  const sendMessage = () => {
    if (message.trim()) {
      socket.emit("send_message", { message });
      setMessage("");
    }
  };

  return (
    <div>
      <h1>Real-Time Message Sharing</h1>
      <input
        type="text"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Type a message..."
      />
      <button onClick={sendMessage}>Send</button>

      <div>
        {receivedMessage.map(msg =>
          (<h1 key={msg}> {msg} </h1>)
        )}
      </div>
    </div>
  );
}

export default App;
